.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
  }
  
  .popup-inner {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .popup h2 {
    text-align: center;
  }
  
  .popup form {
    display: flex;
    flex-direction: column;
  }
  
  .popup label {
    margin-bottom: 10px;
  }
  
  .popup input {
    padding: 8px;
    margin-top: 4px;
  }
  
  .popup button {
    margin-top: 10px;
  }
  
  .regular-updates {
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: black;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .uploaded-images {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .uploaded-images img {
    width: 100px;
    margin: 10px;
  }